.article h1 {
    font-size: 30px;
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 10px;
}

.article h3 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.table td{padding:5px; border:#999 1px solid}