
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f4f9;
    color: #333;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    margin: 20px;
}
.container h1 {
    font-size: 24px;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
}
.container h2 {
    font-size: 20px;
    color: #2980b9;
    margin-top: 20px;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 5px;
}
.container p {
    line-height: 1.7;
    margin: 10px 0;
}
.container ul {
    margin: 10px 0 20px 20px;
}
.container li {
    margin-bottom: 10px;
}
.container a {
    color: #2980b9;
    text-decoration: none;
}
.container a:hover {
    text-decoration: underline;
}
.container .footer {
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: #7f8c8d;
}
