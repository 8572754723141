.article h1 {
    font-size: 30px;
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 10px;
}
.article-body {
    counter-reset: order;
}

.article h3 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
}